// Details about this file: https://thoughtbot.com/upcase/videos/hotwire-example-stimulus-dynamic-forms
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  enable ({ target }) {
    const elements = Array.from(this.element.elements)
    const selectElements = 'selectedOptions' in target ? target.selectedOptions : [target]
    const defaultState = this.#determineState(target)

    for (const element of elements.filter(element => element.name === target.name || element.name.includes(target.name))) {
      if (element instanceof window.HTMLFieldSetElement) element.disabled = defaultState
    }

    for (const element of this.#controlledElements(...selectElements)) {
      if (element instanceof window.HTMLFieldSetElement) element.disabled = !defaultState
    }
  }

  #determineState (target) {
    if (target.type === 'text') {
      return target.value.trim().length === 0
    }

    if (target.type !== 'checkbox') return true

    const isShowOnChecked = !target.dataset.showOnChecked || target.dataset.showOnChecked === 'show'
    return isShowOnChecked ? !target.checked : target.checked
  }

  #controlledElements (...selectedElements) {
    return selectedElements.flatMap(selectedElement => {
      // data-custom-properties is used for selectize.js and data-controls is used for the rest
      const attr = selectedElement.dataset.customProperties || selectedElement.dataset.controls
      return this.#getElementsByTokens(attr)
    })
  }

  #getElementsByTokens (tokens) {
    const ids = (tokens ?? '').split(/\s+/)
    return ids.map(id => document.getElementById(id))
  }
}
