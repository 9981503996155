import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "hiddenInput"];

  connect() {
    this.#updateHiddenInput();
  }

  handleInput({ target }) {
    if (target.value.length === 1) {
      const nextInput = this.inputTargets[this.inputTargets.indexOf(target) + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
    this.#updateHiddenInput();
  }

  handleKeyDown({ target, key }) {
    if (key === "Backspace" && target.value === "") {
      const prevInput = this.inputTargets[this.inputTargets.indexOf(target) - 1];
      if (prevInput) {
        prevInput.focus();
      }
    }
  }

  paste(event) {
    event.preventDefault();
    const pastedData = (event.clipboardData || window.clipboardData).getData("text").replace(/\D/g, "");

    if (!pastedData) return;

    this.#fillInputs(pastedData);
  }

  #fillInputs(data) {
    this.inputTargets.forEach((input, index) => {
      input.value = data[index] || ""; 
    });

    const lastFilledIndex = Math.min(data.length, this.inputTargets.length) - 1;
    if (lastFilledIndex >= 0) {
      this.inputTargets[lastFilledIndex].focus();
    }

    this.#updateHiddenInput();
  }

  #updateHiddenInput() {
    const otpValue = this.inputTargets.map((input) => input.value).join("");
    this.hiddenInputTarget.value = otpValue;
  }
}
