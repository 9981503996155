import { Controller } from '@hotwired/stimulus'
import IMask from 'imask'

export default class ImaskController extends Controller {
  connect () {
    this.#initializeMask()
    if (this.mask) {
      this.mask.on('complete', () => this.dispatch('complete'))
    }
  }

  disconnect () {
    if (this.mask) {
      this.mask.off('complete')
      this.mask.destroy()
    }
  }

  #initializeMask () {
    const maskOptions = this.#getMaskOptions(this.element.dataset.type)
    if (maskOptions) {
      this.mask = new IMask(this.element, maskOptions)
    }
  }

  #getMaskOptions (type) {
    switch (type) {
      case 'currency':
        return {
          mask: Number,
          min: 0,
          thousandsSeparator: ','
        }
      case 'percent':
        return {
          mask: Number,
          min: 0,
          max: 100,
          thousandsSeparator: ',',
          radix: '.'
        }
      default:
        return null
    }
  }

  // updateMaskValue () {
  //   if (this.mask) {
  //     this.mask.updateValue()
  //   }
  // }
}
