import { $getSelection, $isRangeSelection } from 'lexical'
import { $patchStyleText } from '@lexical/selection'
import position from '@yaireo/position'
import ColorPicker from '@yaireo/color-picker'

class ColorPickerController {
  constructor (controller) {
    this.controller = controller
    this.cPicker = this.#createColorPicker()
    this.resizeObserver = new ResizeObserver(this.#observerCallback.bind(this))
    this.intersectionObserver = new IntersectionObserver(this.#observerCallback.bind(this), { root: document, threshold: 1 })
    this.resizeObserver.observe(document.body)
    this.intersectionObserver.observe(this.cPicker.DOM.scope)
    this.#observerCallback()
  }

  resetColor () {
    const selection = $getSelection()
    if (selection && selection.isCollapsed()) {
      this.controller.editor.update(() => {
        $patchStyleText(selection, { color: null })
      })
    }
  }

  #createColorPicker () {
    return new ColorPicker({
      color: this.controller.colorPickerTarget.value,
      defaultFormat: 'hex',
      swatches: this.#getSwatches(),
      swatchesLocalStorage: true,
      buttons: this.#getButtonsConfig(),
      onClickOutside: this.#onClickOutside.bind(this),
      onInput: this.#onInput.bind(this)
    })
  }

  #getSwatches () {
    const defaultSwatches = ['#3F3C43', '#65adff']
    const swatches = this.controller.colorPickerTarget.dataset.swatches
      ? JSON.parse(this.controller.colorPickerTarget.dataset.swatches)
      : []
    return [...swatches, ...defaultSwatches]
  }

  #getButtonsConfig () {
    return {
      undo: { icon: '↶', title: 'Undo' },
      add: { icon: '+', title: 'Add to Swatches' },
      format: { icon: '⇆', title: 'Switch Color Format' }
    }
  }

  #observerCallback (entries) {
    if (!this.cPicker.DOM.scope.classList.contains('hidden')) {
      position({
        target: this.cPicker.DOM.scope,
        ref: this.controller.colorPickerTarget,
        placement: this.controller.colorPickerTarget.dataset.placement || 'center below',
        offset: [20]
      })
    }
  }

  #onClickOutside (e) {
    const isTargetColorInput = e.target === this.controller.colorPickerTarget
    const pickerElem = this.cPicker.DOM.scope
    let showPicker = isTargetColorInput

    if (e.key === 'Escape') showPicker = false

    if (showPicker) {
      this.#showColorPicker(pickerElem)
    } else {
      this.#hideColorPicker(pickerElem)
    }

    if (isTargetColorInput) this.#observerCallback()
  }

  #onInput (color) {
    this.controller.colorPickerTarget.value = color
    this.controller.colorPickerTarget.style.setProperty('--lexical_color', color)

    this.controller.editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $patchStyleText(selection, { color })
      }
    })
  }

  #showColorPicker (pickerElem) {
    if (!document.body.contains(pickerElem)) {
      document.body.appendChild(pickerElem)
    }
  }

  #hideColorPicker (pickerElem) {
    pickerElem.remove()
  }
}

export default function registerColorPickerPlugin (controller) {
  return new ColorPickerController(controller)
}
