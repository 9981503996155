import { Controller } from '@hotwired/stimulus'
import currency from 'currency.js'

export default class extends Controller {
  static targets = ['totalTripCost', 'currency', 'paymentIndex', 'amount', 'remainingAmount', 'remainingCurrency']
  static values = {
    totalAmount: { type: String, default: '0' },
    currency: { type: String, default: 'USD' }
  }

  currencyValueChanged (event) {
    this.#setTotalTripCost()
    this.remainingCurrencyTargets.forEach(target => {
      target.textContent = this.currencyValue
    })
    this.dispatch('currencyChange', { detail: { currency: this.currencyValue } })
  }

  changeRemainingAmount () {
    const totalAmount = this.#stringToCurrency(this.totalAmountValue)
    const remainingAmount = totalAmount.subtract(this.#totalPaymentAmounts())

    this.remainingAmountTargets.forEach(target => {
      target.textContent = this.#stringToCurrency(remainingAmount).format()
    })
  }

  paymentAdd () {
    this.recalculateIndexes()
  }

  recalculateIndexes () {
    let visibleIndex = 0

    this.paymentIndexTargets.forEach((target) => {
      if (target.style.display !== 'none') {
        target.textContent = `#${visibleIndex + 1}`
        visibleIndex++
      }
    })

    this.changeRemainingAmount()
  }

  async setTotalAmount (event) {
    this.totalAmountValue = event.target.value || '0'
    this.#setTotalTripCost()

    await this.dispatch('totalChanged', { detail: { totalAmount: this.totalAmountValue, currency: this.currencyValue } })

    this.changeRemainingAmount()
  }

  setCurrency (event) {
    this.currencyValue = event.target.value || 'USD'
  }

  pullFromCostTab (event) {
    const modal = document.getElementById('turbo-heads-up')

    const isChecked = event.target.checked
    this.totalAmountTarget.disabled = isChecked
    this.currencyTarget.disabled = isChecked
    this.dispatch('onPullFromCostTabChanged')
    if (isChecked) {
      modal.showModal()
    }
  }

  #totalPaymentAmounts () {
    return this.amountTargets
      .map(target => currency(target.value))
      .reduce((total, amount) => total.add(amount), currency(0))
  }

  #setTotalTripCost () {
    this.totalTripCostTarget.textContent = this.#stringToCurrency(this.totalAmountValue, this.currencyValue).format()
  }

  #stringToCurrency (value, symbol) {
    if (!symbol) {
      return currency(value, { pattern: '#', precision: 0, separator: ',' })
    }
    return currency(value, { symbol, pattern: '# !', precision: 0, separator: ',' })
  }
}
