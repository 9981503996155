import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'submit', 'input']

  connect () {
    this.updateSubmitState()
  }

  updateSubmitState () {
    const isChecked = this.#hasCheckedCheckbox();
    const isConfimed = this.inputTarget.value === 'OVERWRITE';

    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !isChecked || !isConfimed;
    }
  }

   #hasCheckedCheckbox () {
    return this.checkboxTargets.some((checkbox)=>checkbox.checked);
  }
}

