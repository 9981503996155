import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  UNDO_COMMAND,
  REDO_COMMAND,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW
} from 'lexical'

import { $getSelectionStyleValueForProperty } from '@lexical/selection'
import { insertList, removeList } from '@lexical/list'
import { $isLinkNode } from '@lexical/link'

class ToolbarActions {
  constructor (controller) {
    this.controller = controller
    this.editor = controller.editor
  }

  undo () {
    this.editor.dispatchCommand(UNDO_COMMAND)
  }

  redo () {
    this.editor.dispatchCommand(REDO_COMMAND)
  }

  formatText (command) {
    this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, command)
  }

  formatElement (command) {
    this.editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, command)
  }

  toggleList (type) {
    this.editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const node = selection.anchor.getNode()
        if (this.isListType(node, type)) {
          removeList(this.editor)
        } else {
          insertList(this.editor, type)
        }
      }
    })
  }

  registerCommands () {
    this.editor.registerCommand(
      CAN_UNDO_COMMAND,
      (payload) => {
        this.controller.undoBtnTarget.disabled = !payload
        return false
      },
      COMMAND_PRIORITY_LOW
    )

    this.editor.registerCommand(
      CAN_REDO_COMMAND,
      (payload) => {
        this.controller.redoBtnTarget.disabled = !payload
        return false
      },
      COMMAND_PRIORITY_LOW
    )
  }

  updateToolbarState (editorState) {
    editorState.read(() => {
      const selection = $getSelection()
      if (!selection) return

      const node = selection.anchor.getNode()
      const parent = node.getParent()

      this.controller.boldBtnTarget.classList.toggle('lexical__btn-active', selection.hasFormat('bold'))
      this.controller.italicBtnTarget.classList.toggle('lexical__btn-active', selection.hasFormat('italic'))
      this.controller.underlineBtnTarget.classList.toggle('lexical__btn-active', selection.hasFormat('underline'))
      this.controller.ulBtnTarget.classList.toggle('lexical__btn-active', this.isListType(node, 'bullet'))
      this.controller.olBtnTarget.classList.toggle('lexical__btn-active', this.isListType(node, 'number'))

      const isLinkNode = $isLinkNode(parent)
      this.controller.linkBtnTarget.classList.toggle('lexical__btn-active', isLinkNode)
      this.controller.linkInputTarget.value = isLinkNode ? parent.getURL() : ''

      const color = $getSelectionStyleValueForProperty(selection, 'color') || '#3F3C43'
      this.controller.colorPickerTarget.value = color
      this.controller.colorPickerTarget.style.setProperty('--lexical_color', color)
    })
  }

  isListType (node, type) {
    if (!node) return false
    if (node.getType() === 'list' && node.getListType() === type) return true
    return this.isListType(node.getParent(), type)
  }
}

export default function registerToolbarActions (controller) {
  const toolbarActions = new ToolbarActions(controller)
  return {
    undo: toolbarActions.undo.bind(toolbarActions),
    redo: toolbarActions.redo.bind(toolbarActions),
    bold: toolbarActions.formatText.bind(toolbarActions, 'bold'),
    italic: toolbarActions.formatText.bind(toolbarActions, 'italic'),
    underline: toolbarActions.formatText.bind(toolbarActions, 'underline'),
    alignLeft: toolbarActions.formatElement.bind(toolbarActions, 'left'),
    alignCenter: toolbarActions.formatElement.bind(toolbarActions, 'center'),
    alignRight: toolbarActions.formatElement.bind(toolbarActions, 'right'),
    alignJustify: toolbarActions.formatElement.bind(toolbarActions, 'justify'),
    listBullet: toolbarActions.toggleList.bind(toolbarActions, 'bullet'),
    listOrdered: toolbarActions.toggleList.bind(toolbarActions, 'number'),
    registerCommands: toolbarActions.registerCommands.bind(toolbarActions),
    updateToolbarState: toolbarActions.updateToolbarState.bind(toolbarActions)
  }
}
