import { sanitizeUrl, isValidUrl } from '~/utils/url'
import { $isRangeSelection, CLICK_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical'
import { $toggleLink } from '@lexical/link'
import { useClickOutside } from 'stimulus-use'
import { getSelectionCoordinates } from '~/utils/window'

const ERROR_CLASS = 'form__input-errored'
const HIDDEN_CLASS = 'hidden'

class LinkActions {
  constructor (controller) {
    this.controller = controller
    this.editor = controller.editor
  }

  applyLink (e) {
    const inputValue = this.controller.linkInputTarget.value.trim()
    const url = sanitizeUrl(inputValue)

    if (url === '') {
      this.unlink()
      return
    }
    if (!isValidUrl(url)) {
      this.controller.linkInputTarget.classList.add(ERROR_CLASS)
      return
    }

    this.controller.linkInputTarget.classList.remove(ERROR_CLASS)
    this.editor.update(() => {
      $toggleLink(url)
    })
    this.closeLinkEditor()
  }

  unlink () {
    this.editor.update(() => { $toggleLink(null) })
    this.closeLinkEditor()
  }

  showLinkEditor (options = { focus: true }) {
    if ($isRangeSelection(this.controller.selection)) {
      const { focus } = options
      const linkEditorElem = this.controller.linkEditorTarget
      const position = getSelectionCoordinates()

      linkEditorElem.style.top = `${position.top + 20}px`
      linkEditorElem.style.left = `${position.left}px`
      linkEditorElem.classList.remove(HIDDEN_CLASS)

      if (focus) {
        this.controller.linkInputTarget.focus()
      }

      useClickOutside(this.controller, { element: this.controller.linkEditorTarget })
    }
  }

  closeLinkEditor () {
    this.controller.linkEditorTarget.classList.add(HIDDEN_CLASS)
  }

  clickOutside () {
    this.closeLinkEditor()
  }

  registerCommands () {
    this.controller.editor.registerCommand(
      CLICK_COMMAND,
      (payload) => {
        const currentElement = payload.target
        const parentElement = currentElement.parentElement

        if (parentElement.tagName === 'A') {
          this.showLinkEditor({ focus: false })
        }
      },
      COMMAND_PRIORITY_LOW
    )
  }
}

export default function registerLinkActions (controller) {
  const linkActions = new LinkActions(controller)
  return {
    applyLink: linkActions.applyLink.bind(linkActions),
    unlink: linkActions.unlink.bind(linkActions),
    showLinkEditor: linkActions.showLinkEditor.bind(linkActions),
    closeLinkEditor: linkActions.closeLinkEditor.bind(linkActions),
    clickOutside: linkActions.clickOutside.bind(linkActions),
    registerCommands: linkActions.registerCommands.bind(linkActions)
  }
}
