import {
  $isTextNode,
  TextNode
} from 'lexical'

export class ExtentedTextNode extends TextNode {
  static getType () {
    return 'extended-text'
  }

  static clone (node) {
    return new ExtentedTextNode(node.__text, node.__key)
  }

  static importDOM () {
    const importers = TextNode.importDOM()
    return {
      ...importers,
      span: () => ({
        conversion: patchStyleConversion(importers?.span),
        priority: 1
      }),
      strong: () => ({
        conversion: patchStyleConversion(importers?.strong),
        priority: 1
      }),
      em: () => ({
        conversion: patchStyleConversion(importers?.em),
        priority: 1
      })
    }
  }
}

function patchStyleConversion (originalDOMConverter) {
  return (node) => {
    const original = originalDOMConverter?.(node)
    if (!original) {
      return null
    }
    const originalOutput = original.conversion(node)

    if (!originalOutput) {
      return originalOutput
    }

    const color = node.style.color

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? ((x) => x)
        const result = originalForChild(lexicalNode, parent)
        if ($isTextNode(result)) {
          const style = [
            color ? `color: ${color}` : null
          ]
            .filter((value) => value != null)
            .join('; ')
          if (style.length) {
            return result.setStyle(style)
          }
        }
        return result
      }
    }
  }
}

export default ExtentedTextNode
