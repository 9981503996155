import {Controller} from '@hotwired/stimulus'
import {Loader} from '@googlemaps/js-api-loader'
import mapIcon from '~/images/map_icon.png'
import {mapStyles} from '~/config/google_map_styles'
import {stimulus} from '~/init'

export default class GoogleMapController extends Controller {
  static targets = ['myMap']
  static values = {
    markers: Object,
    type: String,
    zoomLevel: Number,
    theme: {
      type: String,
      default: 'default'
    }
  }

  async connect () {
    this.markers = []
    await this.#loadGoogleMaps()
    await this.#initializeMap()
    this.#addZoomChangeListener()
  }

  async #loadGoogleMaps () {
    this.maps = new Loader({
      apiKey: process.env.GOOGLE_MAP,
      version: 'weekly',
      libraries: ['maps', 'marker']
    })
    this.googleMaps = await this.maps.load()
  }

  async #initializeMap () {
    const mapElement = this.myMapTarget
    const markers = this.markersValue
    const { Map } = this.googleMaps.maps

    this.map = new Map(mapElement, {
      center: this.#getCenter(markers.center),
      controlSize: 20,
      mapTypeControl: true,
      streetViewControl: false,
      styles: this.themeValue === 'jbd' ? undefined : mapStyles,
      scrollwheel: false,
      mapTypeId: this.themeValue === 'jbd' ? 'terrain' : 'roadmap',
      mapTypeControlOptions: {
        style: this.googleMaps.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        mapTypeIds: this.themeValue === 'jbd' ? [] : ['roadmap', 'hybrid']
      }
    })

    this.#addMarkers(markers.waypoints)
    this.#fitBoundsToMarkers(markers.waypoints)
  }

  #addZoomChangeListener () {
    this.map.addListener('zoom_changed', () => {
      this.#addMarkers(this.markersValue.waypoints)
    })
  }

  #getCenter (center) {
    return {
      lat: parseFloat(center[0]),
      lng: parseFloat(center[1])
    }
  }

  #addMarkers (waypoints) {
    const { Marker, Size } = this.googleMaps.maps

    waypoints.forEach((el) => {
      const marker = new Marker({
        map: this.map,
        icon: {
          url: mapIcon,
          scaledSize: new Size(21, 31)
        },
        position: {
          lat: parseFloat(el.latitude),
          lng: parseFloat(el.longitude)
        },
        label: {
          className: 'overview-map-label',
          fontSize: '11px'
        }
      })

      this.markers.push(marker)
    })
  }

  #createInfoWindow (title) {
    const formattedText = title.split(':').join(':<br>')
    return new this.googleMaps.maps.InfoWindow({
      content: `<div id="infoWindowContent" class="text-center text-xs leading-relaxed">${formattedText}</div>`,
      headerDisabled: true,
      maxWidth: 320
    })
  }

  #addMarkerListeners(marker) {
    this.googleMaps.maps.event.addListener(infowindow, 'domready', () => {
      const infoWindowContent = document.getElementById('infoWindowContent')
      infoWindowContent.addEventListener('mouseover', () => {
        clearTimeout(this.closeTimeout)
      })
      infoWindowContent.addEventListener('mouseout', () => {
        this.closeTimeout = setTimeout(() => {
          infowindow.close()
        }, 500)
      })
    })
  }

  #removeMarkers () {
    this.markers.forEach((marker) => marker.setMap(null))
  }

  #fitBoundsToMarkers (waypoints) {
    const bounds = new this.googleMaps.maps.LatLngBounds()

    waypoints.forEach((waypoint) => {
      bounds.extend({
        lat: parseFloat(waypoint.latitude),
        lng: parseFloat(waypoint.longitude)
      })
    })
    this.map.fitBounds(bounds)

    this.googleMaps.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      if (waypoints.length === 1) {
        this.map.setZoom(8)
      }
    })
  }
}

stimulus.register('google-map', GoogleMapController)
