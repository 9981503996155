import { registerControllers } from 'stimulus-vite-helpers'
import { stimulus } from '~/init'
import AutoSubmit from '@stimulus-components/auto-submit'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'
import Confirmation from '@stimulus-components/confirmation'
import HwComboboxController from '@josefarias/hotwire_combobox'

stimulus.register('auto-submit', AutoSubmit)
stimulus.register('textarea-autogrow', TextareaAutogrow)
stimulus.register('checkbox-select-all', CheckboxSelectAll)
stimulus.register('confirmation', Confirmation)
stimulus.register('hw-combobox', HwComboboxController)

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(stimulus, controllers)
