import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['newInvoice', 'newFromTemplate', 'templateSelect', 'submitButton']

  connect () {
    this.#updateState()
  }

  chooseNewInvoice () {
    this.dispatch('onChooseNewInvoice')
    this.#setActive(this.newInvoiceTarget)
    this.#removeActive(this.newFromTemplateTarget)
    this.templateSelectTarget.classList.add('hidden')
    this.#updateSubmitButtonState()
  }

  chooseNewFromTemplate () {
    this.#setActive(this.newFromTemplateTarget)
    this.#removeActive(this.newInvoiceTarget)
    this.templateSelectTarget.classList.remove('hidden')
    this.#updateSubmitButtonState()
  }

  clickTemplateSelect (e) {
    this.#updateSubmitButtonState()
  }

  #setActive (target) {
    target.classList.add('active', 'border-gray-500', 'bg-gray-50')
  }

  #removeActive (target) {
    target.classList.remove('active', 'border-gray-500', 'bg-gray-50')
  }

  #updateSubmitButtonState () {
    const isTemplateSelected = this.templateSelectTarget.querySelector('select')?.value !== ''

    if (this.newInvoiceTarget.classList.contains('active')) {
      this.submitButtonTarget.disabled = false
    } else if (this.newFromTemplateTarget.classList.contains('active')) {
      this.submitButtonTarget.disabled = !isTemplateSelected
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  #updateState () {
    this.#updateSubmitButtonState()

    if (this.newFromTemplateTarget.classList.contains('active')) {
      this.templateSelectTarget.classList.remove('hidden')
    } else {
      this.templateSelectTarget.classList.add('hidden')
    }
  }
}
